import Vue from 'vue';
import Vuex from 'vuex';
import {
  getLanguage,
  getTemplate,
  getProductDetail,
  getHomeConfig,
  getUserData,
  shopCart,
  guideInfo,
  getPre,
  createOrder
} from '@/server/api.js';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    // {object} 语言data
    languageData: {},
    // {array} 语言list
    languageList: [],
    // {object} 语言list
    currency: '',
    // {object} 模板信息
    templateInfo: {},
    // {array} banner list
    bannerSwiper: [],
    ids: '',
    // {array} 商品页data
    productData: {},
    // {array} 商品页banner list
    productSwiper: [],
    // {string} 首页标题
    indexTile1: '',
    indexTile2: '',
    userInfo: {},
    cartList: [],
    guideObj: {},
    // 总价格
    totalPrice: '',
    orderInfo: {},
    orderInfos: {},
    updateToken: '',
    // {boolean} 退出登录
    logOut: false,
    // {object} 选择地址信息
    currentAddr: {}
  },
  mutations: {
    // 设置语言list
    SET_LANGUAGELIST(state, guideData) {
      state.languageList = guideData;
    },
    // 设置货币
    SET_CURRENCY(state, guideData) {
      state.currency = guideData;
    },
    SET_TEMPLATE(state, guideData) {
      state.templateInfo = guideData;
    },
    SET_BANNERSWIPER(state, guideData) {
      state.bannerSwiper = guideData;
    },
    SET_IDS(state, guideData) {
      state.ids = guideData;
    },
    SET_PRODUCTDATA(state, guideData) {
      state.productData = guideData;
    },
    SET_SWIPERSWIPER(state, guideData) {
      state.productSwiper = guideData;
    },
    SET_TITLE1(state, guideData) {
      state.indexTile1 = guideData;
    },
    SET_TITLE2(state, guideData) {
      state.indexTile2 = guideData;
    },
    SET_USERINFO(state, guideData) {
      state.userInfo = guideData;
    },
    SET_CARTLIST(state, guideData) {
      state.cartList = guideData;
    },
    SET_GUIDEOBJ(state, guideData) {
      state.guideObj = guideData;
    },
    SET_TOTALPRICE(state, guideData) {
      state.totalPrice = guideData;
    },
    SET_ORDERINFO(state, guideData) {
      state.orderInfo = guideData;
    },
    SET_ORDERINFOS(state, guideData) {
      state.orderInfos = guideData;
    },
    SET_UPDATETOKEN(state, guideData) {
      state.updateToken = guideData;
    },
    SET_LOGOUT(state, guideData) {
      state.logOut = guideData;
    },
    SET_CURRENTADDR(state, guideData) {
      state.currentAddr = guideData;
    }
  },
  actions: {
    /**
     * 获取语言
     * @param {object} commit
     * @param {*} data 参数
     */
    languageData({ commit }, data) {
      getLanguage(data)
        .then((res) => {
          if (res && res.data) {
            commit('SET_LANGUAGE', res.data);
          }
        })
        .catch(() => {});
    },
    /**
     * 获取模板信息
     * @param {object} commit
     * @param {*} data 参数
     */
    templateData({ commit }, data) {
      getTemplate(data)
        .then((res) => {
          if (res && res.data) {
            if (res.data.code === 1) {
              let getBanner = res.data.data.home[0].content.list;
              let getIds = res.data.data.home[1].content.ids;
              commit('SET_TEMPLATE', res.data);
              commit('SET_BANNERSWIPER', getBanner);
              commit('SET_IDS', getIds);
              localStorage.setItem('ids', getIds);
            }
          }
        })
        .catch(() => {});
    },
    /**
     * 获取首页广告信息和tab页多语言
     * @param {object} commit
     * @param {*} data 参数
     */
    homeConfig({ commit }, data) {
      getHomeConfig(data)
        .then((res) => {
          if (res && res.data) {
            if (res.data.code === 1) {
              let getData = res.data.data;
              commit('SET_BANNERSWIPER', getData.pc_banner);
              commit('SET_TITLE1', getData.ad_page);
              commit('SET_TITLE2', getData.product_page);
            }
          }
        })
        .catch(() => {});
    },
    /**
     * 获取商品详情
     * @param {object} commit
     * @param {*} data 参数
     */
    productDetailData({ commit }, data) {
      getProductDetail(data)
        .then((res) => {
          if (res && res.data) {
            if (res.data.code === 1) {
              let getBanner = res.data.data.images;
              commit('SET_PRODUCTDATA', res.data.data);
              commit('SET_SWIPERSWIPER', getBanner);
            }
          }
        })
        .catch(() => {});
    },
    /**
     * 用户信息
     * @param {object} commit
     * @param {*} data 参数
     */
    getUserInfo({ commit }, data) {
      getUserData(data)
        .then((res) => {
          if (res && res.data) {
            if (res.data.code === 1) {
              commit('SET_USERINFO', res.data.data);
            }
          }
        })
        .catch(() => {});
    },
    /**
     * 购物车list
     * @param {object} commit
     * @param {*} data 参数
     */
    getShopCart({ commit }, data) {
      shopCart(data)
        .then((res) => {
          if (res && res.data) {
            if (res.data.code === 1) {
              commit('SET_CARTLIST', res.data.data);
            }
          }
        })
        .catch(() => {});
    },
    /**
     * 购物、付款、配送、订单说明（多语言）
     * @param {object} commit
     * @param {*} data 参数
     */
    getGuideInfo({ commit }, data) {
      guideInfo(data)
        .then((res) => {
          if (res && res.data) {
            if (res.data.code === 1) {
              commit('SET_GUIDEOBJ', res.data.data);
            }
          }
        })
        .catch(() => {});
    },
    /**
     * 计算订单的总金额
     * @param {object} commit
     * @param {*} data 参数
     */
    getTotal({ commit }, data) {
      getPre(data)
        .then((res) => {
          if (res && res.data) {
            if (res.data.code === 1) {
              commit('SET_TOTALPRICE', res.data.data);
            }
          }
        })
        .catch(() => {});
    },
    /**
     * 创建订单
     * @param {object} commit
     * @param {*} data 参数
     */
    createProductOrder({ commit }, data) {
      createOrder(data)
        .then((res) => {
          if (res && res.data) {
            commit('SET_ORDERINFOS', res.data);
            if (res.data.code === 1) {
              commit('SET_ORDERINFO', res.data.data);
            }
          }
        })
        .catch(() => {});
    }
  }
});
export default store;
