import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/Index.vue'),
      children: [
        {
          path: '',
          component: () => import('@/views/main/Index'),
          children: [
            {
              path: '',
              redirect: 'index'
            },
            {
              path: 'index',
              name: 'index',
              component: () => import('@/views/main/MainPage')
            },
            {
              path: '/product',
              name: 'ProductDetails',
              component: () => import('@/views/main/ProductDetails.vue')
            },
            {
              path: '/buy-now',
              name: 'BuyNow',
              component: () => import('@/views/main/BuyNow.vue')
            },
            {
              path: '/confirm-order',
              name: 'ConfirmOrder',
              component: () => import('@/views/main/ConfirmOrder.vue')
            },
            {
              path: '/success',
              name: 'OrderSuccess',
              component: () => import('@/views/main/OrderSuccess.vue')
            },
            {
              path: '/cart',
              name: 'ShopCart',
              component: () => import('@/views/main/ShopCart.vue')
            },
            {
              path: '/order-details',
              name: 'OrderDetails',
              component: () => import('@/views/main/OrderDetails.vue')
            },
            {
              path: '/address',
              name: 'ChooseAddress',
              meta: {
                keepAlive: false
              },
              component: () => import('@/views/main/ChooseAddress.vue')
            },
            {
              path: '/personal',
              // name: 'PersonalPage',
              component: () => import('@/views/main/PersonalCenter.vue'),
              children: [
                {
                  path: '',
                  name: 'OrderList',
                  component: () =>
                    import('@/views/main/personalCenter/OrderList')
                },
                {
                  path: 'address',
                  name: 'AddressList',
                  component: () =>
                    import('@/views/main/personalCenter/AddressList')
                },
                {
                  path: 'modify-password',
                  name: 'ModifyPassWord',
                  component: () =>
                    import('@/views/main/personalCenter/ModifyPassWord')
                }
              ]
            },
            {
              path: '/content',
              name: 'content',
              component: () => import('@/views/main/ContentView.vue')
            }
          ]
        },
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/login/Index')
        },
        {
          path: '/404',
          name: '404',
          component: () => import('@/views/404')
        },
        {
          path: '*',
          redirect: '/404'
        }
      ]
    }
  ]
});
router.afterEach((to, from, next) => {
  console.log(to, from, next);
  window.scrollTo(0, 0);
});

// 解决重复路由报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
