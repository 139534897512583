'use strict';
import Vue from 'vue';
import router from '@/router';
import axios from 'axios';

let config = {
  //本地联调测试用
  // baseURL: process.env.BASE_URL, // apxi的base_url
  //测试环境
  baseURL: process.env.NODE_ENV === 'development' ? process.env.BASE_URL : '/',
  url: '',
  params: {}
};

//创建axios的一个实例
const _axios = axios.create(config);
_axios.config = config;

//请求拦截器
_axios.interceptors.request.use(
  function (config) {
    let getLang = localStorage.getItem('lang') || 'default';
    let getCur = localStorage.getItem('currency');
    config.url = config.url + '?language=' + getLang + '&currency=' + getCur;
    let getBaseUrl = localStorage.getItem('baseURL');
    let getToken = localStorage.getItem('token');
    if (getBaseUrl) {
      config.baseURL = getBaseUrl;
    }
    if (getToken) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      let token = getToken;
      config.headers.token = `${token}`;
    }
    if (config.method === 'get') {
      config.params.dataTime = Date.parse(new Date());
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

//响应拦截器
_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error, 'error');
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          router.replace({
            path: '/login'
          });
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
