import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store/';
import axios from 'axios'; // 安装axios后引入
import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { getLanguage } from '@/server/api.js';
import currencyListData from '@/utils/currency.json';
import statisticsList from '@/utils/statistics.json';
import '@/assets/css/flags.css';
async function init() {

  // 百度统计
  var _hmt = _hmt || [];
  (function() {
    // var hm = document.createElement("script");
    // let clientRoute = location.href.split('//')[1].split('/')[0]
    // let clientKey = statisticsList[clientRoute]
    // console.log('%c 🚀clientKey-22', 'font-size:13px; background:#4e52d9; color:#9296ff;', clientKey)
    // console.log('xx', `https://hm.baidu.com/hm.js?${clientKey}`)
    // hm.src = `https://hm.baidu.com/hm.js?${clientKey}`;
    // var s = document.getElementsByTagName("script")[0]; 
    // s.parentNode.insertBefore(hm, s);
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?eb3d7499e226e44f3ce9321b1125040a";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
  })();

  Vue.prototype.$axios = axios;
  Vue.config.productionTip = false;
  Vue.use(VueI18n);
  Vue.use(ElementUI);
  // 设置默认语言
  let getLocale = '';
  // 根据api返回信息设置i18n
  let res = await getLanguage({});
  if (res.data.code === 1) {
    let language = [];
    let getData = res.data.data;
    for (let key in getData) {
      if (key != 'currency') {
        language.push({
          name: getData[key],
          key: key
        });
      }
    }
    store.commit('SET_LANGUAGELIST', language);
    // 默认货币name
    if (!localStorage.getItem('currency')) {
      localStorage.setItem('currency', getData.currency);
    }
    // 根据货币name设置货币符号
    let data = currencyListData.filter((item) => {
      return item.name == localStorage.getItem('currency');
    });
    store.commit('SET_CURRENCY', data[0].dialCode);
    // 默认语言
    if (!localStorage.getItem('i18n')) {
      localStorage.setItem('lang', 'default');
      localStorage.setItem('langname', getData.default);
    }
    if (getData.default === '中文繁体') {
      getLocale = 'tc';
    } else if (getData.default === 'English') {
      getLocale = 'en';
    } else if (getData.default === 'Bahasa Indonesia') {
      getLocale = 'yn';
    } else if (getData.default === 'Vietnamese') {
      getLocale = 'vn';
    } else if (getData.default === 'Thai') {
      getLocale = 'tha';
    }
    localStorage.setItem('local', getLocale);
    if (localStorage.getItem('i18n')) {
      getLocale = localStorage.getItem('i18n');
    }
  }
  const i18n = new VueI18n({
    locale: getLocale,
    messages: {
      yn: require('./utils/i18n/langs/text-yn.json'),
      en: require('./utils/i18n/langs/text-en.json'),
      tc: require('./utils/i18n/langs/text-tc.json'),
      vn: require('./utils/i18n/langs/text-vn.json'),
      tha: require('./utils/i18n/langs/text-tha.json')
    }
  });
  window._i18n = i18n;
  Vue.prototype._i18n = i18n;
  new Vue({
    router,
    store,
    axios,
    i18n,
    render: (h) => h(App)
  }).$mount('#app');
}
init();
