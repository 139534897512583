import axios from '@/plugins/axios.js';
let gateway = '/api';
/**
 * 登录
 * @export
 * @param {*} data
 * @returns
 */
export function login(data) {
  return axios({
    url: `${gateway}/addons/shopro/user/accountLogin`,
    method: 'post',
    data: data
  });
}
/**
 * 注册
 * @export
 * @param {*} data
 * @returns
 */
export function register(data) {
  return axios({
    url: `${gateway}/addons/shopro/user/register`,
    method: 'post',
    data: data
  });
}
/**
 * 获取验证码
 * @export
 * @param {*} data
 * @returns
 */
export function send(data) {
  return axios({
    url: `${gateway}/addons/shopro/sms/send`,
    method: 'post',
    data: data
  });
}
/**
 * 忘记密码
 * @export
 * @param {*} data
 * @returns
 */
export function forgotPwd(data) {
  return axios({
    url: `${gateway}/addons/shopro/user/forgotPwd`,
    method: 'post',
    data: data
  });
}
/**
 * 退出登录
 * @export
 * @param {*} data
 * @returns
 */
export function loginOut(data) {
  return axios({
    url: `${gateway}/addons/shopro/user/logout`,
    method: 'post',
    data: data
  });
}
/**
 * 用户信息
 * @export
 * @param {*} data
 * @returns
 */
export function getUserData(data) {
  return axios({
    url: `${gateway}/addons/shopro/user`,
    method: 'get',
    params: data
  });
}
/**
 * 购物车
 * @export
 * @param {*} data
 * @returns
 */
export function shopCart(data) {
  return axios({
    url: `${gateway}/addons/shopro/cart`,
    method: 'post',
    data: data
  });
}
/**
 * 加入购物车
 * @export
 * @param {*} data
 * @returns
 */
export function addCart(data) {
  return axios({
    url: `${gateway}/addons/shopro/cart/add`,
    method: 'post',
    data: data
  });
}
/**
 * 购物、付款、配送、订单说明（多语言）
 * @export
 * @param {*} data
 * @returns
 */
export function guideInfo(data) {
  return axios({
    url: `${gateway}/addons/shopro/guide/list`,
    method: 'get',
    params: data
  });
}
/**
 * 计算订单的总金额
 * @export
 * @param {*} data
 * @returns
 */
export function getPre(data) {
  return axios({
    url: `${gateway}/addons/shopro/order/pre`,
    method: 'post',
    data: data
  });
}
/**
 * 创建订单
 * @export
 * @param {*} data
 * @returns
 */
export function createOrder(data) {
  return axios({
    url: `${gateway}/addons/shopro/order/createOrder`,
    method: 'post',
    data: data
  });
}
/**
 * 订单详情
 * @export
 * @param {*} data
 * @returns
 */
export function orderDetails(data) {
  return axios({
    url: `${gateway}/addons/shopro/order/detail`,
    method: 'get',
    params: data
  });
}
/**
 * 获取语言
 * @export
 * @param {*} data
 * @returns
 */
export function getLanguage(data) {
  return axios({
    url: `${gateway}/addons/shopro/base/getLanguage`,
    method: 'get',
    params: data
  });
}
/**
 * 首页首页信息
 * @export
 * @param {*} data
 * @returns
 */
export function getTemplate(data) {
  return axios({
    url: `${gateway}/addons/shopro/index/template`,
    method: 'get',
    params: data
  });
}
/**
 * 首页banner
 * @export
 * @param {*} data
 * @returns
 */
export function getBannerList(data) {
  return axios({
    url: `${gateway}/addons/shopro/banner/list`,
    method: 'get',
    params: data
  });
}
/**
 * 首页广告信息
 * @export
 * @param {*} data
 * @returns
 */
export function getHomeConfig(data) {
  return axios({
    url: `${gateway}/addons/shopro/home_config/detail`,
    method: 'get',
    params: data
  });
}
/**
 * 首页商品列表
 * @export
 * @param {*} data
 * @returns
 */
export function getProductList(data) {
  return axios({
    url: `${gateway}/addons/shopro/goods/lists`,
    method: 'get',
    params: data
  });
}
/**
 * 商品详情页接口
 * @export
 * @param {*} data
 * @returns
 */
export function getProductDetail(data) {
  return axios({
    url: `${gateway}/addons/shopro/goods/detail`,
    method: 'get',
    params: data
  });
}
/**
 * 获取地址list
 * @export
 * @param {*} data
 * @returns
 */
export function getAddress(data) {
  return axios({
    url: `${gateway}/addons/shopro/address`,
    method: 'get',
    params: data
  });
}
/**
 * 修改地址信息
 * @export
 * @param {*} data
 * @returns
 */
export function editAddress(data) {
  return axios({
    url: `${gateway}/addons/shopro/address/edit`,
    method: 'post',
    data: data
  });
}
/**
 * 更改购物车商品
 * 删除地址
 * @export
 * @param {*} data
 * @returns
 */
export function delAddress(data) {
  return axios({
    url: `${gateway}/addons/shopro/address/del`,
    method: 'post',
    data: data
  });
}
/**
 * 删除购物车商品
 * @export
 * @param {*} data
 * @returns
 */
export function editCart(data) {
  return axios({
    url: `${gateway}/addons/shopro/cart/edit`,
    method: 'post',
    data: data
  });
}
/**
 * 获取所有订单列表
 * @export
 * @param {*} data
 * @returns
 */
export function getOrderList(data) {
  return axios({
    url: `${gateway}/addons/shopro/order/index`,
    method: 'get',
    params: data
  });
}
/**
 * 修改密码
 * @export
 * @param {*} data
 * @returns
 */
 export function changePassword(data) {
  return axios({
    url: `${gateway}/addons/shopro/user/changePwd`,
    method: 'post',
    data: data
  });
}
